@import 'variables/bitters';
@import 'variables/neat';
@import 'variables/custom_pallete';

$baseColor: #5f5f5f;
$white: #fff;
$offWhite: #fafafa;
$blue: #002e6e;
$black: #000000;
$lightBlue: #eaf5fa;
$gold: #c1b46b;
$grey: #666666;
$lightGrey: #a6b1c2;
$bdrGrey: #eff0f2;
$bdrGrey1: #e3e7ec;
$brightBlue: #536dfe;
$pink: #ec407a;
$purple: #9c27b0;
$yellow: #fecd54;
$green: #2cd889;
$darkGreen: #22b41c;
$red: #8f002c;
$lightred: #ffe9e9;
$offgray: #f9f9f9;
$dGray: #212121;

$lightRed: #e72146;
// $primary:linear-gradient(to right, #06034C, #0073B2);
$primary: #2cd889;
$primaryHover: #75f5b9;
$primary: #2cd889; // Bootstrap variable
$primary-light: #75f5b9; // Custom variable
$primary-inverse: #ffffff; // Custom variable
$dark-inverse: #fff6f0; // Custom variable
$white: #fff; // Custom variable
$Gray: #b9b9b9;
$offwhite: #f5f5f5;
$light: #e6e6e6;

$lightOrange: #f9e8ce;
$darkOrange: #e18b0b;
$lightGreen: #d3f0d2;

$fs14: 14px;
$fs15: 15px;
$fs16: 16px;
$fs20: 20px;
$fs24: 24px;
$fs30: 30px;

$pad0: 0;

$mrg5: 5px;

$lh24: 24px;

$space10: 10px;
$space20: 20px;
$space30: 30px;
$space36: 36px;

$text-uppercase: uppercase;
$text-capitalize: capitalize;
$text-center: center;

$bdr0: none;
$bdr1: 1px;

$absolute: absolute;
$relative: relative;
$fixed: fixed;

$theme-transition: all 0.3s ease;

$pointer: pointer;

$flex: flex;
$block: block;
$none: none;
$inlineBlock: inline-block;
$alignCenter: center;
$justifyCenter: center;
$justifyBetween: space-between;
$column: column;

$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$sm: 576px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1439px;
$xxxl: 1599px;
$xxxxl: 1700px;

$d-block: block;
