@import '~bootstrap/scss/bootstrap';
@import './variables.scss';
@import './mixins.scss';

.tableRight {
  background: $white;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  width: 645px;
  position: absolute;
  padding: 30px;
  box-shadow: 0px 4px 8px #00000029;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000 !important;

  // @media (max-width: $xxl) {
  //   width: 325px;
  // }

  // @media (max-width: $xl) {
  //   width: 300px;
  //   top: 66px;
  // }

  // @media (max-width: $md) {
  //   position: inherit;
  //   top: auto;
  //   bottom: auto;
  //   right: auto;
  //   width: 100%;
  //   overflow-y: auto;
  //   background: transparent;
  //   box-shadow: none;
  //   padding-bottom: 0;
  // }
}
.rightModal {
  width: 0px;
  opacity: 0;
}
.listSection {
  padding-bottom: $space10 + 5;
  border-bottom: 1px solid $bdrGrey;
  margin-bottom: $space10 + 5;

  @media (max-width: $xxl) {
    padding-bottom: $space10;
    margin-bottom: $space10;
  }
}
.bdrNone {
  border: 0 !important;

  @media (max-width: $md) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.login-wrap{
  margin-top: 40px;
}
.swal2-title{
  font-size: 16px !important;
}
.swal2-popup.swal2-toast .swal2-title{
  margin:unset;
}
.post-ads-wrap{
  margin-top: 40px;
}
.four_zero_four_bg {
  background-image: url(../assets/img/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
